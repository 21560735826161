// FOOTER CSS
.page-wrap {
    min-height: 100%;
    /* equal to footer height */
    margin-bottom: -5em; 
}
.page-wrap:after {
    content: "";
    display: block;
}
.site-footer, .page-wrap:after {
    height: 10em; 
}
.site-footer {
    border-top: 0.5px solid #979797;
}

.site-footer * {
    background-color: #FAFAFA;
}
   
.footer-median {
    border-top: 0.5px solid #80979797;
    width: 140vw;
    height: 1px;
    margin-left: -20vw;
}

footer p, footer li, footer a {
    font-size: 0.75rem;
}

footer li {
    text-decoration: none;
    list-style: none;
    padding: 0;
}

footer .col-8 {
    padding-right: 30%;
}

footer .col-8 > p {
    padding-left: 1rem;
}

#Footer_Foyer_Home {
    height: 4em;
}

footer a img {
    height: 1.5em;
}

.footer-desc {
    max-width: 25rem !important;
}