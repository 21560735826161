// PRODUCT CSS
.product_selection {
    z-index: 2;
    cursor: pointer;
}

div[data-id="VS_SELECTOR_DISPLAY"] {
    display: flex;
    justify-content: flex-end;
}

div[data-id="product"] h1 {
    height: fit-content;
}

.what_is_insight_button {
    flex-direction: column;
    border: none;
    padding: 0.75rem;
    border-radius: 10px;
    font-weight: 100;
    display: flex;
    justify-content: center;
}

.what_is_insight_play {
    width: 20px; 
    float: left;
}

.insight_core_features_container img {
    height: 3em;
}

#Foyer_Overview_Selection img {
    cursor: pointer;
    height: 3em;
    border-bottom: 2px solid #6a2d2d2d;
    width: 100%;
    padding-bottom: 0.25rem;

    &:hover {
        transform: scale(1.02);
    }
}

div[data-id*="_DISPLAY"] {
    background-color: #03C04A !important;
    color: #F7F7F7 !important;
}

h1[id*="_HEADING"] {
    padding-top: 0.5em;
}

.learn_more_button {
    background-color: transparent;
    border: none;
    font-size: 1rem;
    color: #03C04A;

    &:focus {
        outline: none !important;
    }
}

.learn_more * {
    color: #03C04A;
    cursor: pointer;

    &:hover {
        color: #517860;
        transform: scale(1.02);
    }
}

#Foyer_Overview_Selection img.active {
    border-bottom: 2px solid #03C04A;
    padding-bottom: 0.25rem;
}

.vs-line {
    height: 0.25rem;
    background-color: #517860;
    width: 5.5rem;
    margin-left: -15%;
    margin-right: 0.25rem;
    margin-bottom: 0.75rem;
}

.logo-4r {
    height: 3rem !important;
    margin-top: 0.5rem !important;
}

.mh-30r {
    max-height: 30rem !important;
}

.mh-40r {
    max-height: 40rem !important;
}

.mh-25r {
    max-height: 25rem !important;
}

.recommendation_engine_container {
    display: flex;
    justify-content: space-evenly;
    align-items: end;
}

.recommendation_engine_chat_svg {
    max-width: 92%;
    margin-left: 3%;
    margin-right: 5%;
}

.logo-h {
    height: 132.859px !important;
    width: 345px !important;
}

.vl {
    border-left: 3px solid #2d2d2d;
    height: 20rem;
}

#product_select {
    box-shadow: 1px 1px 7px -1px #1c1c1c;
    z-index: 2;
    position: relative;
}

// ANIMATION
#stay-in-place {
    height: 100%;
    width: 50%;
    background: #F5F5F5;
    position: absolute;
    -webkit-transition: all 1s, -webkit-transform 1s;
    transition: all 1s, transform 1s, z-index .8s; 
    text-indent: 10px;
    z-index:0;
}

#move-in-to-place {
    position: absolute;
    height: 100%;
    width: 50%;
    background: #F5F5F5;
    right: 0px;
    text-indent: 10px;
    -webkit-transition: all 1s, -webkit-transform 1s;
    transition: all 1s, transform 1s;
}

#textbox-stay {
  position: absolute;
  text-indent: 0px;
  opacity: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: block;
  line-height: 1em; /* a */
  max-height: 9em; /* a x number of line to show (ex : 2 line)  */
}

#textbox-move {
  position: absolute;
  text-indent: 0px;
  opacity: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: block;
  line-height: 1em; /* a */
  max-height: 9em; /* a x number of line to show (ex : 2 line)  */
}

#move-in-to-place:hover, #move-in-to-place.hovered {
    -webkit-transition: all 1s, -webkit-transform 1s;
    transition: all 1s, transform 1s;
    width: 75%;
    text-indent: 0px;
    z-index: 100;
}

#stay-in-place:hover, #stay-in-place.hovered {
    -webkit-transition: all 1s, -webkit-transform 1s;
    transition: all 1s, transform 1s;
    width: 75%;
    text-indent: 100px;
    z-index: 200;
}

#stay-in-place:hover h1 {
    white-space: nowrap;
}

#move-in-to-place:hover #textbox-move, #move-in-to-place.hovered #textbox-move {
  opacity: 1;
  transition: all 1s ease .5s;
}

#stay-in-place:hover #textbox-stay, #stay-in-place.hovered #textbox-stay {
  opacity: 1;
  transition: all 1s ease .5s;
  left: 100px;
}

#stay-in-place:hover #AI_Heading, #stay-in-place.hovered #AI_Heading {
    transition: font-size .5s ease;
    font-size: 2.5rem;
}

#move-in-to-place:hover #VS_Heading, #move-in-to-place.hovered #VS_Heading {
    transition: font-size .5s ease;
    font-size: 2.5rem;
}

#AI_Heading {
    font-size: 2rem;
    transition: font-size .5s ease;
}

#VS_Heading {
    font-size: 2rem;
    transition: font-size .5s ease;
}

// Imported Inline Styles
.col-9 {
    flex-grow: 1 !important;
    // transition: all 400ms ease !important;
}

.col-6 {
    flex-shrink: 1 !important;
    // transition: all 400ms ease !important;
}

.col-3 {
    flex-shrink: 1 !important;
    // transition: all 400ms ease !important;
}

#VS_DESC h1.vert-rl, #AI_DESC h1.vert-rl {
    writing-mode: vertical-rl !important;
}

#vs_overview {
    padding-bottom: 2rem;
}

#ai-internal-title {
    font-size: 1.2rem;
}

#ai-internal-body {
    font-size: .8rem;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

.fade-in {
    animation: fadeIn ease 0s !important;
    -webkit-animation: fadeIn ease 0s !important;
    -moz-animation: fadeIn ease 0s !important;
    -o-animation: fadeIn ease 0s !important;
    -ms-animation: fadeIn ease 0s !important;
}

#AI_DESC p, #VS_DESC p {
    font-size: .85rem !important;
    font-weight: 100 !important;
}

@media (min-width: 1024px) {
    .ml-5rem--l {
        margin-left: -5rem;
    }
}

/* Extremly Large Screens && Monitors */
@media (min-width: 1525px) {
    /* Products.html */
    .insight_container {
        max-width: 80vw !important;
        width: 80vw !important;
    }

    .insight_container .banner_decor--1 {
        left: calc(50% + 1rem) !important;
    }

    .insight_container .banner_decor--2 {
        left: calc(50% + 56px + 1rem) !important;
    }
}

@media (max-width: 1023px) {
    .product_landing_h1 {
        font-size: 2rem;
    }

    div.remove-all img {
        display: none !important; 
    }
}

@media (max-width: 1670px) {
    .mh-40r {
        max-height: 20rem !important;
    }
}

@media (max-width: 1670px) {
    .mh-25r {
        max-height: 20rem !important;
    }
}

@media (max-width: 990px) {
    #internal-content {
        display: none;
    }
}

@media (max-width: 460px) {
    .mh-40r {
        max-height: 15rem !important;
    }
}

#envision_content {
    width: auto;
}