// CONTACT CSS
.contact_us_side_content img {
    margin-right: 1rem;
    width: 1em;
    height: 1.25em;
}

.contact_us_side_content > span {
    display: flex;
    align-items: center;
    font-size: 1em;
    font-weight: 100;
    margin: 0.75rem;
}

.contact_us_side_content address {
    margin-top: -9px;
    margin-bottom: 0px;
}

.get_in_touch_container {
    display: block;
    position: relative;
    overflow: hidden;
}

.get_in_touch_form {
    position: relative;
    float: right;
    margin-top: 3rem;
    margin-right: 0px;
    width: 75%;
    height: 100%;
    z-index: 1;
    padding-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5rem;
}

.get_in_touch_side_container {
    position: relative;
    float: left;
    z-index: 2;
    height: 31em;
    margin-top: -36rem;
    width: 30%;
    display: flex;
    align-items: center;
}

@media (max-width: 1023px) {
    .get_in_touch_container {
        height: fit-content !important;
    }

    .get_in_touch_primary {
        width: 100% !important;
        float: none !important;
        padding: 3rem !important;
    }
    
    .get_in_touch_secondary {
        width: 100% !important;
        margin-top: initial !important;
        padding: 3rem !important;
    }
}
