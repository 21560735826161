// TEAM CSS 
#Team img {
    max-height: 100%;
}

.roundedBox {
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    box-shadow: 0 0 1px 1px rgba(31,45,61,.15);
}

.close_modal {
    position: absolute;
    top: 1em;
    right: 1em;
}

.teamImageContainer div.imageshadow{display:none}
.teamImageContainer img:last-child{display:none}
.teamImageContainer:hover img:first-child{display:none}
.teamImageContainer:hover div.imageshadow{
    display:inline-block;
    z-index: 1;
    transform: scale(.98);
}
.teamImageContainer:hover img:last-child{
    display:inline-block; 
    transform: scale(.98);
}

.teamImageContainer {
    position: relative;
    width: 100%;
    margin-bottom: 0.5rem;
}
.profilePicture {
    width: 100%;
    object-fit: contain;
    border-radius: 0.5rem;
    cursor: pointer;
}

.imageshadow {
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 0px 10px 0px #00000096;
    border-radius: 10px;
    cursor:pointer;
}

.name {
    font-weight: bold;
}

.contact_icon {
    height: 2em !important;
}

/* The Modal (background) */
.modal {
    display: none;
    position: fixed;
    z-index: 99;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #000000bb
}

/* Modal Content */
.modal-content {
    position: relative;
    background-color: #f9f9f9;
    padding: 20px;
    max-width: 80vw;
    max-height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

@media (min-width: 1550px) {
    .modal-content {
        height: 80vh;
    }
}

/* The Close Button */
.close {
    background-color: #03c04a;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    color: white;
    position: absolute;
    right: 25px;
    font-size: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close:hover,
.close:focus {
    color: #ccc;
    text-decoration: none;
    cursor: pointer;
}

.mySlides {
    display: none;
}

.slideColumn {
    height: auto;
}

.innerSlide {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.innerFoyerHouse {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.slideName {
    padding: 10px;
    background-color: #222222;
    color: #f9f9f9;
}

.teammate {
    margin: 1rem 0px;
}
.contactIcons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.modalTeamMember {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 10px;
    font-size: 1.75vh;
}
