// UTILITY CSS
.u-display-block {
    display: block;
}

.u-display-flex {
    display: flex;
}

.u-display-inline-block {
    display: inline-block;
}

.u-display-none {
    display: none !important;
}

.u-flex-end {
    justify-content: flex-end;
}

.u-declare-child-font-white * {
    color: #f9faf9;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}
.u-declare-child-font-blk * {
    color: #464646;
}

.u-color-green {
    color: #03C04A !important;
}

.u-color--blk {
    color: #464646 !important;
}

.u-color--white {
    color: #f9faf9 !important;
}

.u-color--light_green {
    color: #03C04A !important;
}

.u-color--light_grey {
    color: #707070 !important;
}

.u-background--secondary_white {
    background-color: #FAFAFA;
}

.u-background--green {
    background-color: #517860;
}

.u-background--light_green {
    background-color: #9AE6B7;
}

.u-background--grey {
    background-color: #F5F5F5;
}

.u-underline-green {
    border-bottom: 2px solid #03C04A;
    width: fit-content;
}

.u-center {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
}

.u-vert-center {
    display: flex;
    align-items: center !important;
}

.u-justify-center {
    display: flex;
    justify-content: center !important;
}

.u-baseline {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.u-nowrap {
    white-space: nowrap !important;
}

.u-pointer {
    cursor: pointer;
}

.u-pointer-none {
    pointer-events: none;
}

.u-pointer-auto {
    pointer-events: auto;
}
 
.u-vert-rl {
    writing-mode: vertical-rl;
}

.u-scale--normal:hover {
    transform: scale(1.05);
}

.u-margin-right-none {
    margin-right: 0px;
}

.u-vw-full {
    width: 100vw;
}

.u-pos-rel {
    position: relative;
}

.u-box-shadow {
    box-shadow: -2px 2px 7px -3px #1c1c1c73;
}

.u-box-shadow--light {
    box-shadow: 0px 5px 7px -3px #C9C9C9;
}

.u-closing-content {
    margin-bottom: 0 !important;
    padding-bottom: 10rem !important;
}

.u-lev-1 {
    z-index: 1;
    position: relative;
}

.u-lev-2 {
    z-index: 2;
    position: relative;
}

.u-resize {
    max-height: 20rem;
    width: auto;
}

.toggle_full {
    height: 100vh;
}

.toggle_half {
    height: 50vh;
}

.u-extended_display {
    transform: translateX(-50%);
}

.u-full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

@media (max-width: 1023px) {
    .u-rel-xs {
        position: relative !important;
    }

    .toggle_full, .toggle_half {
        height: fit-content;
    }

    .toggle_padding_1rem--left {
        padding-left: initial;
    }

    .remove_column_gutters col, .remove_column_gutters .col-1, .remove_column_gutters .col-2 {
        display: none;
    }

    .col-md-hidden {
        display: none !important;
    }

    .col-s-hidden {
        display: none !important;
    }

    .u-background--grey_md {
        background-color: #F5F5F5;
    }

    .col-100-vw--md {
        width: 100vw;
        max-width: 100vw;
    }

    .col-12--hidden {
        display: none;
    }

    .u-background--grey_xs {
        background-color: #F5F5F5;
    }
}