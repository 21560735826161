// HOME CSS
html, body {
    max-width: 100%;
    height: 100%;
    overflow-x: hidden;
}

html {
    scroll-behavior: smooth;
}

button a {
    color: #FFF;
}

.navbar-brand img {
    width: 10em;
}

a {
    color: #464646;
    text-decoration: none;
    background-color: transparent;

    &:hover {
        color: #03C04a !important; 
        transform: scale(1.05);
        transition: all .25s ease-in-out;
    }
}

form label, form p, form h1, form h2 {
    color: #F9f9f9 !important;
}

form input, form textarea {
    color: #464646 !important;
    padding: 2.5px 5px;
    width: 100%;
    margin: 0.25rem auto;
    border-radius: 2px;
    border: none;
    box-shadow: -2px 2px 7px -1px #1c1c1c;
}

#home_title {
    text-align: left;
    padding-bottom: 10px;
}

#home_title_main {
    text-align: left;
    padding-bottom: 10px;
    font-size: 1.2em;
    letter-spacing: 1px;
}

#home_title_content {
    text-align: left;
    padding-bottom: 10px;
    font-size: .75em;
}

#home_block_title h2 {
    padding-bottom: 20px;
}

#who_is {
    margin-top: 7em !important;
    margin-bottom: 7em !important;
}

#Foyer .nav-link.active {
    color: #9AE6B7;
}

.green_button a:hover {
    color: #F9f9f9 !important;
}

.green_button {
    padding: 10px 20px;
    background-color: #03c04a;
    border: none;
    color: white;
    max-height: fit-content;
    white-space: nowrap;
}

.btn_short {
    width: 200px !important;
}

.green_button:hover {
    color: #F9f9f9;
    background-color: #9AE6B7;
    //transform: scale(1.05);
    //transition: all .25s ease-in-out;
}

.clear_button {
    padding: 10px 20px;
    background-color: #F9F9F900;
    border: solid 2px;
    color: white;
    max-height: fit-content;
    white-space: nowrap;
}

.clear_button:hover {
    color: white;
    background-color: #F9F9F9;
    //transform: scale(1.05);
    //transition: all .25s ease-in-out;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.25rem;
    text-align: center;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    margin: 0;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    height: fit-content;
}

.two_minute_overview--button {
    flex-direction: column;
    border: none;
    padding: 0.5rem 1.25rem;
    border-radius: 10px;
    font-weight: 100;
    display: flex;
    justify-content: center;
}

.two_minute_overview--play {
    width: 20px; 
    float: left;
}

.get_in_touch_info--container {
    padding: 3rem 3.5rem;
}

.get_in_touch_info--underline {
    width: 100%;
    margin-bottom: 0.5rem;
    margin-left: -2.5rem;
}

.get_in_touch_info--textbox {
    width: 100%; 
    min-height: 200px;
}

/* Index Banner Decor */
.banner_container {
    width: 85%;
    // margin-left: calc(16% + 0.75rem);
    margin: -3rem auto 0;
}

.banner_decor_container {
    position: absolute;
    z-index: 1;
    bottom: -2em;
    left: -3em;
}

.banner_decor--1 {
    width: 8em;
}

.banner_decor--2 {
    margin-left: -0.275em;
    width: 8em;
}

.banner_decor--3 {
    width: 8em;
    margin-top: -0.275em;
    margin-left: 10.775em;
}

/* Banner Content */
.banner_container > div {
    justify-content: center;
    display: flex;
    align-items: center;
}

@media (min-width: 1280px) {
    .col-lg-hidden {
        display: none;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .banner_decor--1, .banner_decor--2, .banner_decor--3 {
        display: none;
    }

    .banner_decor--1, .banner_decor--2, .banner_decor--3 {
        display: none;
    }

    .banner_container {
        margin-top: -3em;
        width: 75%;
    }

    .banner_container > div {
        margin-bottom: 2rem;
        background-color: #FAFAFA;
        border-radius: 4px;
        box-shadow: -2px 2px 7px -1px #1c1c1c;
    }

    .col-md-hidden {
        display: none;
    }

    .u-background--grey_md {
        background-color: #F5F5F5;
    }
}

@media (max-width: 1023px) {
    /* Index.html  */
    .banner_decor--1, .banner_decor--2, .banner_decor--3 {
        display: none;
    }

    .banner_container {
        overflow: visible;
        height: fit-content;
        box-shadow: none !important;
        background-color: #FFF !important;
    }

    .banner_container > div {
        margin-bottom: 2rem;
        background-color: #FAFAFA;
        border-radius: 4px;
        box-shadow: -2px 2px 7px -1px #1c1c1c;
    }
}

@media (max-width: 1276px) {
    .banner_container {
        overflow: visible;
        height: fit-content;
        box-shadow: none !important;
        background-color: #FFF !important;
    }
}

@media (min-width: 1024px) {
    .u-banner-lg-4 {
        flex: 0 0 auto;
        width: 33.3333333333%;
    }
}

@media (max-width: 1200px) {
    img {
        max-height: 27rem;
    }
}

/* Smaller than iPad  */
@media (max-width: 767px) {
    .col-xs-hidden {
        display: none !important;
    }
}