@font-face {
    font-family: 'Product Sans';
    src: url('../fonts/product-sans/ProductSans-Regular.woff2') format('woff2'),
        url('../fonts/product-sans/ProductSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/open/OpenSans-Italic.woff2') format('woff2'),
        url('../fonts/open/OpenSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/open/OpenSans-BoldItalic.woff2') format('woff2'),
        url('../fonts/open/OpenSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/open/OpenSans-Regular.woff2') format('woff2'),
        url('../fonts/open/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/open/OpenSans-Bold.woff2') format('woff2'),
        url('../fonts/open/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

.hugeText {
    font-weight: bold;
    font-size: 2rem;
}

.whiteButton {
    color: #03c04a;
    background-color: #f9f9f9;

    &:hover {
        background-color: #9AE6B7;
        color: #f9f9f9;
        border: none;
        transform: scale(1.05);
        transition: all .25s ease-in-out;
    }
}

.hexagon {
    padding: calc(5rem + 50vh) 0 5rem 0;
    background-image: url(/assets/images/svg/HexagonBG/70.svg);
    animation: animatedBackground 75s linear infinite;
    width: 100vw;
    margin-top: -50vh;
    background-color: #f9faf9;
}

#product_hexagon {
    background-image: none;
    background-color: #fff;
    animation: none;
}

#header_title h1 {
    font-size: 1.2em;
}

@keyframes animatedBackground {
    from { background-position: 0 0; }
    to { background-position: 1243px 1351px; }
}

.verticalMargin {
    margin: 6rem 0px 4rem 0px;
}

.showcase {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.vh-75 {
    height: 75vh !important;
}

.vh-50 {
    height: 50vh !important
}

.h-2em {
    height: 2em;
}

.h-0 {
    height: 0;
}

.page_header_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-spacing {
    height: 82px;
    margin-bottom: 2rem;
}