// FONT CSS
#root body, input, p, div, label, button {
    font-family: 'Open Sans', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Lato', sans-serif;
}

path {
    font-family: 'Product Sans', sans-serif;
}