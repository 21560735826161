.dateAndAuthor {
    font-size: 0.875em;
    color: #707070;
}

.title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 1.5rem;
    padding-top: 10px;
}

.blogtitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 1.0rem;
    padding-top: 10px;
}

.excerpt {
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.blogexcerpt {
    font-size: .8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
}

.page_number {
    margin: 10px;
    font-size: 0.75rem;
}

.dotted_square {
    height: 6em !important;
}

.press_link {
    transform: none !important;
}

.press_link p {
    color: #464646 !important;
}

.read_more {
    border: none;
    background-color: transparent;
    padding-left: 0;

    &:hover {
        transform: scale(1.02);
    }
}

#blogimage {
    width: 396px;
    max-width: 100%;
    height:160px !important;
}

.cover{
    object-fit: cover;
}

img[src*="#andrew"] {
    width: 210px;
    height: auto;
    float: right;
    margin-left: 10px;
    margin-right: 25px;
    box-shadow: 2px 2px 5px gray;
    transition: box-shadow .1s ease 0s, border .1s ease 0s;
    border: 0px solid black;
}

img[src*="#andrew"]:hover {
    box-shadow: 0px 0px 0px gray;
    transition: box-shadow .1s ease 0s, border .1s ease 0s;
    border: 2px solid black;
}